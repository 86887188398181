<template>
	<div class="Main width_1200 margin_auto" style="background-color: #fafafa">
		<div class="dexBox">
			<img src="https://zy.metaera.media/assets/images/dex/983.png" />
		</div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb dexBoxm"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft">
				<div class="dexHeader flex align_items_c">
					<div class="headerActice"> 每日精选 </div>
				</div>
				<div class="jxlist">
					<div
						class="eightclock_pc align_items_c pointer"
						v-for="(item, index) in NewsDetails"
						:key="index"
						@click="
							$router.push({
								path: '/gw_detail',
								query: { id: item.id },
							})
						"
					>
						<wzitem :item="item"></wzitem>
					</div>
					<scrollToBottom @reachBottom="getDataListPage"></scrollToBottom>
				</div>
			</div>
			<div class="MainRight">
				<div style="margin-top: 20px"></div>
				<resou></resou>
				<!-- 主编推荐 -->
				<event type="recommend" title="主编推荐" style="margin-top: 30px">
				</event>
				<download></download>
			</div>
		</div>
	</div>
</template>

<script>
import { getNewsDetails } from "@/api/home";
import wzitem from "./../../components/pc/wzitem.vue";
import download from "./../../components/pc/download.vue";
import { eventBus } from "../../components/pc/leftTab";
export default {
	name: "",
	components: { wzitem, download },
	data() {
		return {
			NewsDetails: [],
			page: 1,
			size: 10,
			total: "",
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	created() {
		this.getNewsDetails();
	},
	methods: {
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			this.getNewsDetails(
				{
					page: this.page,
					size: this.size,
				},
				0
			);
		},
		getNewsDetails() {
			getNewsDetails({ page: this.page, size: this.size })
				.then((res) => {
					if (res.code == 200) {
						if (res.data.page == 1) {
							this.NewsDetails = res.data.list;
						} else {
							this.NewsDetails = this.NewsDetails.concat(res.data.list);
						}
						this.total = res.data.total;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 10px;
}

.MainLeft {
	width: 600px;
}
.MainRight {
	margin-left: 30px;
	display: block;
}

.dexBox {
	width: 100%;
	height: 120px;
}
.dexBox img {
	width: 100%;
	height: 120px;
	object-fit: cover;
}
.dexHeader {
	margin-top: 10px;
	height: 60px;
	margin-left: 20px;
}
.jxlist {
	background-color: #fff;
	border-radius: 20px;
}
.headerActice {
	position: relative;
	font-size: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}
.MainRight_1 {
	width: 100%;
	background: #ffffff;
	border-radius: 18px 18px 18px 18px;
	opacity: 1;
	height: 500px;
	overflow-y: auto;
}
.MainRight_2 {
	padding-left: 0;
	margin-top: 20px;
	padding-bottom: 40px !important;
}
.eightclock_pc {
	display: contents;
}

.timeOut {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #9b9b9b;
}
.kxsteps {
	padding-left: 16px;
	margin-top: 20px;
}
@media screen and (max-width: 800px) {
	.Main {
		padding-top: 10px;
	}
	.MainLeft {
		width: 100%;
		background-color: #fff;
		border-radius: 20px;
	}
	.MainRight {
		display: none;
	}
	.dexBox {
		width: 100%;
		height: 100px;
		background-image: url("https://zy.metaera.media/assets/images/dex/983.png");
		background-size: 100% 100%;
	}
	.pagination-container {
		margin-left: -20px;
	}
}
</style>
<style>
.dexBoxm .wzitem {
	padding-bottom: 20px;
	border-bottom: 1px solid #efefef;
}
.dexBoxm .wzitem:nth-last-child(1) {
	border-bottom: none;
}
.kxsteps .el-step__main {
	/* height: 99px; */
}
.kxsteps .el-step__description {
	padding-right: 0% !important;
}
.kxsteps .btnitem:nth-last-child(1) {
	margin-left: 10px;
}
</style>
